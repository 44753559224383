import React from 'react'

import Link from '../components/link'
import SEO from '../components/seo'
import travoltaGif from '../images/travolta.gif'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="container text-center">
      <img className="mx-auto" src={travoltaGif} alt="travolta.gif" />
      <h1 className="mt-10">THESE ARE NOT THE DROIDS YOU ARE LOOKING FOR</h1>
      <div className="flex flex-col md:flex-row items-stretch md:justify-center items-center mt-10">
        <Link className="btn-primary my-2 md:my-0 mx-2" to="/">
          Go to Home Page
        </Link>
        <Link className="btn-primary my-2 md:my-0 mx-2" to="/films">
          See what movies are showing
        </Link>
        <Link className="btn-primary my-2 md:my-0 mx-2" to="/events">
          What events are happening
        </Link>
      </div>
    </div>
  </>
)

export default NotFoundPage
